@media (min-width: 481px) {
  .pageBase {
    width: 100vw;
    height: 100%;
    background-image: url("../images/websiteBackground.jpg");
    background-size: cover;
    background-attachment: fixed;
    position: static;
  }

  .namePlateContainer {
    display: flex;
    flex-direction: row;
    width: 100%;
    box-sizing: border-box;
    justify-content: space-between;
    padding-top: 10%;
    padding-left: 10%;
    padding-right: 10%;
  }

  #profilePicture {
    width: 100%;
    border-radius: 50%;
    aspect-ratio: 1/1;
    /* filter: grayscale(80%); */
    object-fit: cover;
    border: 20px solid transparent;
    padding: 10px;
    background: linear-gradient(#11998e, #38ef7d) padding-box,
      linear-gradient(to top, #11998e, #38ef7d) border-box;
  }

  #picAndLinks {
    width: 30%;
  }

  .mainSection {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding-left: 10%;
    padding-right: 10%;
    box-sizing: border-box;
    padding-top: 12%;
  }

  .aboutMeContainer {
    display: flex;
    align-items: center;
    padding: 50px;
    text-align: center;
  }

  .aboutHeader {
    color: white;
    font-size: 80px;
    font-weight: 600;
    width: 600px;
    text-align: center;
  }
}
