.education {
  display: flex;
  flex-direction: row;
  background-color: rgba(211, 211, 211, 0.5);
  backdrop-filter: blur(5px);
  padding: 10px;
  align-items: center;
}

.education img {
  width: 100px;
  height: 100px;
  margin-right: 3%;
  border: 2px solid black;
  object-fit: contain;
}

#textContent {
  display: flex;
  flex-direction: column;
  font-size: 20px;
  font-weight: 300;
}

#title {
  font-size: 30px;
  font-weight: 500;
}
