.NavContainer {
  width: 80vw;
  left: 10%;
  border-radius: 30px;
  height: 60px;
  position: fixed;
  align-items: center;
  transition: opacity 0.5s ease-in-out;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  border-bottom: 1px solid white;
  display: flex;
  flex-wrap: wrap;
  z-index: 999;
}

.text {
  color: white;
  font-size: 30px;
  font-weight: 400;
  position: relative;
  text-decoration: none;
}

.text:hover {
  color: gray;
}

.NavContainer.fade-out {
  opacity: 0;
}
