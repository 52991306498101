/* For desktops */
@media (min-width: 481px) {
  .navBarContainer {
    position: fixed;
    box-sizing: border-box;
    height: 100vh;
    left: -121px;
    display: flex;
    align-items: center;
    transition: left 0.3s ease;
    z-index: 2;
  }
  .navBarContainer:hover {
    left: 0;
  }

  .linksContainer {
    display: flex;
    flex-direction: column;
    border-right: 2px solid orange;
    border-top: 2px solid orange;
    border-bottom: 2px solid orange;
    background-color: rgb(60, 59, 59);
    width: 80%;
    height: 80%;
    padding: 10px;
    color: white;
    justify-content: space-around;
    margin-right: 4%;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
  }

  .IconContainer {
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .Icon {
    font-size: 5em;
    color: gray;
    transition: color 0.3s ease, box-shadow 0.3s ease;
  }
  .Icon:hover {
    color: white;
    cursor: pointer;
  }

  #hoverText {
    padding: 1%;
    opacity: 70%;
    background-color: rgb(49, 49, 49);
    border: 1px solid orange;
    border-radius: 0.2em;
    position: absolute;
  }

  .Link {
    color: white;
    text-decoration: none;
  }

  .indicatorArrow {
    color: gray;
    font-size: 5em;
    transition: font-size 0.3s ease, transform 0.3s ease;
  }
  .navBarContainer:hover .indicatorArrow {
    transform: rotate(180deg);
  }
}
