@media (min-width: 481px) {
  .splashContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: white;
  }

  #Starter {
    font-size: 4em;
  }

  #Name {
    font-size: 6vw;
    text-shadow: black 15px 15px 30px;
    font-weight: 200;
  }

  #Subtitle {
    margin-top: 1em;
    font-size: 1.5em;
    font-weight: 200;
    display: flex;
    justify-content: flex-end;
  }

  #links {
    margin-top: 1em;
    display: flex;
    justify-content: space-evenly;
  }
  #links a {
    margin-right: 20px;
  }
}

@media (max-width: 480px) {
  .Name {
    font-size: 60px;
    font-weight: 600;
    left: 0%; /* To conteract the left outside this media query */
  }

  .Subtitle {
    font-size: 15px;
    font-weight: 600;
    left: 0%;
    padding-left: 10px;
    padding-right: 10px;
  }
}
