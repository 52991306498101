@media (max-width: 480px) {
  .pageBaseAbout {
    background-color: #1a191c;
    overflow: hidden;
  }

  .pageContent {
    display: flex;
    flex-direction: column;
    position: relative;
    align-items: center;
    color: white;
    font-size: 20px;
  }

  .AboutHeader {
    color: white;
    font-size: 80px;
    padding-top: 90px;

    margin-bottom: 60px;
    font-weight: 100;
  }

  .AboutContent {
    color: white;
    font-size: 20px;
    padding-left: 90px;
    padding-right: 90px;
    line-height: 40px;
    padding-top: 25px;
  }

  .linksAbout {
    position: relative;
    display: flex;
    flex-direction: column;
    padding-left: 90px;
    padding-right: 90px;
    padding-top: 70px;
  }

  .linksAbout a {
    margin-right: px;
    margin-top: 15px;
    display: flex;
    flex-direction: row;
    color: gray;
    text-decoration: none;
  }

  .linksAbout p {
    margin-left: 10px;
  }

  .linksAbout a:hover {
    text-decoration: underline;
  }

  .technologiesHeader {
    color: white;
    font-size: 80px;
    text-align: center;
    font-weight: 100;
    margin-top: 90px;
  }

  .myStackHeadersAndIcons {
    color: white;
    display: flex;
    margin-top: 30px;
    box-sizing: border-box;
    justify-content: space-evenly;
    width: 100%;

    padding: 20px;
  }

  .myStackHeadersAndIcons h1 {
    margin-bottom: 10px;
    font-size: 30px;
    font-weight: 100;
  }

  .listDiv {
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
    text-align: start;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
  }

  .listDivItem {
    display: flex;
    flex-direction: column;
    padding: 20px;
  }

  ul {
    flex-wrap: wrap;

    margin: 0px;
    height: 200px;
  }
  li {
    font-size: 20px;
  }

  .hobbiesContainer {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    text-align: center;
    padding-left: 90px;
    padding-right: 90px;
    padding-top: 90px;
    font-size: 20px;
  }

  .hobbiesContainer p {
    font-size: 20px;
  }

  .hobbiesHeader {
    color: white;
    font-size: 80px;
    font-weight: 100;
  }
}

@media (min-width: 481px) {
  .pageBaseAbout {
    background-color: #1a191c;
    overflow: hidden;
  }

  .pageContent {
    display: flex;
    flex-direction: column;
    position: relative;
    align-items: center;
    color: white;
    font-size: 20px;
    padding-left: 500px;
    padding-right: 500px;
  }

  .AboutHeader {
    color: white;
    font-size: 80px;
    padding-top: 90px;
    margin-bottom: 60px;
    font-weight: 100;
  }

  .AboutContent {
    color: white;
    font-size: 16px;
    padding: 3%;
    margin: 3px;
    border: 2px solid black;
    background-color: rgb(56, 54, 54);
    box-shadow: 20px 20px rgb(42, 41, 41);
    border-radius: 30px;
  }

  .linksAbout {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-left: 90px;
    padding-right: 90px;
    padding-top: 70px;
  }

  .linksAbout a {
    margin-right: px;
    margin-top: 15px;
    display: flex;
    flex-direction: row;
    color: gray;
    text-decoration: none;
  }

  .linksAbout p {
    margin-left: 10px;
  }

  .linksAbout a:hover {
    text-decoration: underline;
  }

  .technologiesHeader {
    color: white;
    font-size: 80px;
    text-align: center;
    font-weight: 100;
    margin-top: 90px;
  }

  .myStackHeadersAndIcons {
    color: white;
    display: flex;
    margin-top: 30px;
    box-sizing: border-box;
    justify-content: space-evenly;
    width: 100%;

    padding: 20px;
  }

  .myStackHeadersAndIcons h1 {
    margin-bottom: 10px;
    font-size: 30px;
    font-weight: 100;
  }

  .listDiv {
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
    text-align: start;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
  }

  .listDivItem {
    display: flex;
    flex-direction: column;
    padding: 20px;
  }

  ul {
    flex-wrap: wrap;

    margin: 0px;
    height: 200px;
  }
  li {
    font-size: 20px;
  }

  h1 {
    text-align: center;
  }

  .hobbiesContainer {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    text-align: center;
    padding-left: 90px;
    padding-right: 90px;
    padding-top: 90px;
    font-size: 20px;
  }

  .hobbiesContainer p {
    font-size: 20px;
  }

  .hobbiesHeader {
    color: white;
    font-size: 80px;
    font-weight: 100;
  }
}
