@media (max-width: 480px) {
  .FooterContainer {
    width: 100%;
    height: 400px;
    background-color: black;
    padding: 50px;
    box-sizing: border-box;
    margin-top: 120px;
  }

  .PersonalInfoContainer {
    width: 100%;
  }

  .PersonalInfoHeader {
    color: white;
    font-size: 20px;
    text-align: center;
    padding-bottom: 25px;
    border-bottom: 1px solid gray;
    font-weight: 700;
  }

  .LinksContainer {
    display: flex;
    padding-top: 25px;
    flex-direction: column;
    font-size: 20px;
  }

  .LinksContainer a {
    display: flex;
    flex-direction: row;
    max-width: 400px;
    color: gray;
    text-decoration: none;
    padding-bottom: 20px;
  }

  .LinksContainer a:hover {
    text-decoration: underline;
    color: white;
  }

  .LinksContainer p {
    margin-left: 20px;
  }

  .FooterNavContainer {
    font-size: 15px;
    width: 100%;
  }

  .NavSectionHeaders {
    color: white;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: relative;
    left: 10%;
    top: 0;
  }

  .NavSectionRoutes {
    flex-direction: column;
    display: flex;
  }

  .HeaderLink {
    color: gray;

    text-decoration: none;
  }

  .HeaderLink:hover {
    text-decoration: underline;
    color: white;
  }
}

@media (min-width: 481px) {
  .FooterContainer {
    width: 100%;
    height: 400px;
    background-color: black;
    padding: 50px;
    box-sizing: border-box;
    margin-top: 120px;
  }

  .PersonalInfoContainer {
    width: 100%;
  }

  .PersonalInfoHeader {
    color: white;
    font-size: 20px;
    padding-bottom: 25px;
    border-bottom: 1px solid gray;
    font-weight: 700;
  }

  .LinksContainer {
    display: flex;
    padding-top: 25px;
    flex-direction: column;
    font-size: 20px;
  }

  .LinksContainer a {
    display: flex;
    flex-direction: row;
    max-width: 400px;
    color: gray;
    text-decoration: none;
    padding-bottom: 20px;
  }

  .LinksContainer a:hover {
    text-decoration: underline;
    color: white;
  }

  .LinksContainer p {
    margin-left: 20px;
  }

  .FooterNavContainer {
    font-size: 15px;
    width: 100%;
  }

  .NavSectionHeaders {
    color: white;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: relative;
    top: -150px;
    left: 60%;
    width: 40%;
  }

  .NavSectionRoutes {
    flex-direction: column;
    display: flex;
  }

  .HeaderLink {
    color: gray;

    text-decoration: none;
  }

  .HeaderLink:hover {
    text-decoration: underline;
    color: white;
  }
}
