@media (max-width: 480px) {
  .BGgradient {
    width: 100vw;
    height: 1000px;
    background-image: linear-gradient(
        to bottom,
        rgba(26, 25, 28, 0),
        rgba(26, 25, 28, 1)
      ),
      url(../images/HeaderBackground2.jpg);
    background-size: cover;
  }

  .pageBase {
    width: 100%;
    background-color: #1a191c;
    position: static;
    overflow-x: hidden;
  }

  .namePlateContainer {
    justify-content: center;
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    text-align: center;
    align-items: center;
    padding-top: 260px;
  }

  .links {
    position: relative;
    padding-top: 25px;
    display: flex;
    justify-content: space-evenly;
    width: 50%;
  }

  .profilePictureContainer {
    padding-top: 500px;
    position: relative;

    width: 90%;
    height: 50%;
    bottom: 0%;
    left: 0%;
  }

  .mainSection {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding-top: 300px;
  }

  .aboutMeContainer {
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: column;
    background-color: rgb(38, 37, 39);
    width: 60%;
    padding: 50px;
  }

  .aboutHeader {
    color: white;
    font-size: 50px;
    font-weight: 600;
    width: 600px;
    text-align: center;
  }

  .aboutDescription {
    color: white;
    margin-top: 10px;
    font-size: 20px;
    width: 300px;
    text-align: center;
  }
}
