@media (max-width: 480px) {
  .projectCardContainer {
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
    background-color: #4d4a49;
    border-radius: 30px;
  }

  .projectCardContentContainer {
    display: flex;
    flex-direction: column;
  }

  title {
    font-size: 60px;
    font-weight: 500;
    color: white;
    padding-top: 10px;
  }

  description {
    font-size: 20px;
    color: white;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 10px;
  }

  .linksContainerPortfolio {
    font-size: 3rem;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    padding: 0;
  }

  .linksPortfolio {
    color: black;
    padding-left: 20px;
    padding-right: 20px;
  }

  .linksPortfolio:hover {
    color: white;
  }

  .imageContainer {
    width: 50px;
    height: 50px;
    display: flex;
    position: relative;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .imageContainer img {
    width: 50px;
    height: 50px;
    display: flex;
    position: relative;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  tools {
    width: 40%;
    font-weight: 600;
    font-size: 18px;
    padding: 20px;
    color: #d4d0cf;
  }

  activeDate {
    font-weight: 600;
    font-size: 18px;
    color: #d4d0cf;
  }
}

@media (min-width: 481px) {
  .projectCardContainer {
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
    background-color: #4d4a49;
    border-radius: 30px;
  }

  .projectCardContentContainer {
    display: flex;
    flex-direction: column;
  }

  #title {
    font-size: 60px;
    font-weight: 500;
    color: white;
    padding-top: 10px;
  }

  #description {
    font-size: 20px;
    color: white;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 10px;
  }

  #linksContainerPortfolio {
    font-size: 3rem;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    padding: 0;
  }

  .linksPortfolio {
    color: black;
    padding-left: 20px;
    padding-right: 20px;
  }

  .linksPortfolio:hover {
    color: white;
  }

  .imageContainer {
    width: 50px;
    height: 50px;
    display: flex;
    position: relative;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .imageContainer img {
    object-fit: contain;
  }

  #tools {
    width: 40%;
    font-weight: 600;
    font-size: 18px;
    padding: 20px;
    color: #d4d0cf;
  }

  #activeDate {
    font-weight: 600;
    font-size: 18px;
    color: #d4d0cf;
  }
}
