@media (max-width: 480px) {
  .pageBasePortfolio {
    width: 100%;
    background-color: #1a191c;
  }

  .pageContentPortfolio {
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
    box-sizing: border-box;

    background-color: #1a191c;
  }

  .completedSectionHeader {
    font-size: 50px;
    color: white;
    margin: 0;
    padding-top: 70px;
    padding-bottom: 70px;
    text-align: center;
  }
}

@media (min-width: 481px) {
  .pageBasePortfolio {
    width: 100%;
    background-color: #1a191c;
  }

  .pageContentPortfolio {
    width: 100%;
    padding-left: 10%;
    padding-right: 10%;
    box-sizing: border-box;
    background-color: #1a191c;
  }

  .completedSectionHeader {
    font-size: 50px;
    color: white;
    margin: 0;
    padding-top: 150px;
    padding-bottom: 70px;
    text-align: center;
  }
}
